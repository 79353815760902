import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import posed from 'react-pose'
import GoogleLogin from 'react-google-login'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Modal from '../components/modal'
import Calendar from '../components/calendar'
import Image from '../components/image'

const FadeUp = posed.div({
  exit: {
    y: '100px',
    opacity: 0,
  },
  enter: {
    y: '0%',
    opacity: 1,
    beforeChildren: true,
    staggerChildren: 50,
    transition: { duration: 1000 },
  },
})

const MakeYourImpactPage = props => {
  const MODAL_IDEA = 'idea'
  const MODAL_DONATE = 'donate'
  const MODAL_SUGGESTION = 'suggestion'
  const MODAL_CALENDAR = 'calendar'
  const [modalIdea, setModalIdea] = useState(false)
  const [modalDonate, setModalDonate] = useState(false)
  const [modalSuggestion, setModalSuggestion] = useState(false)
  const [modalCalendar, setModalCalendar] = useState(false)
  const [isGoogleSignedIn, setGoogleSignedIn] = useState(false)
  const [emailForm, setEmailForm] = useState('')

  const handleModal = (name, isVisible) => {
    document.body.className = isVisible ? 'modal-open' : ''

    switch (name) {
      case MODAL_IDEA:
        setModalIdea(isVisible)
        break
      case MODAL_DONATE:
        setModalDonate(isVisible)
        break
      case MODAL_SUGGESTION:
        setModalSuggestion(isVisible)
        break
      case MODAL_CALENDAR:
        setModalCalendar(isVisible)
        break
      default:
    }
  }

  const responseOnSuccessGoogle = response => {
    response.googleId && setGoogleSignedIn(true)
    // console.log(response);
  }
  const responseOnFailureGoogle = response => {
    // console.log(response);
  }

  const popupUploadForm = () => {
    const url = `https://docs.google.com/forms/u/2/d/e/1FAIpQLSfamaCK32DeNokrwSuW7Yj-4SoJ6uTeiqVYZlmIsXY-1PjR2g/formResponse?entry.1300542717=${emailForm}`
    window.open(url, 'Upload Documents/Photos', 'width=853,height=700')
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const actionStage = props.location.state
    const actionForm =
      actionStage != null && typeof actionStage === 'object'
        ? actionStage.actionForm === 'donation-form'
        : false
    actionForm && handleModal(MODAL_DONATE, true)
  }, [props.location.state])

  return (
    <Layout>
      <SEO title="Make Your Impact" />
      <section className="text-white position-relative text-center">
        <Image
          filename="make-your-impact/hero-image.jpg"
          className="img-fluid"
          style={{ minHeight: '300px' }}
        />
        <FadeUp
          initialPose="exit"
          pose="enter"
          className="container make-your-impact-box py-3 py-sm-5"
        >
          <h1 className="display-4 font-weight-bold mb-3">MAKE YOUR IMPACT</h1>
          <p className="h5 mb-2">
            Ready to act? We have a whole host of activities for
            <br className="d-none d-md-block" /> you to get involved in; or even
            start your own initiative!
          </p>
        </FadeUp>
      </section>
      <div className="container pt-5">
        <p className="h5 text-center">
          Here are ways you can join the global Energi Impact family, and give
          back to the planet and communities that have given us so much.{' '}
        </p>
        <p className="h5 text-center mt-5">Join the revolution below.</p>
      </div>
      <section id="donation-section" className="container">
        <div className="row">
          <div className="col-md-6 col-lg mt-5">
            <div
              className="d-flex flex-column bg-green p-4 text-white text-center h-100"
              data-aos="fade-right"
            >
              <h3 className="mb-3">SUBMIT YOUR IDEA</h3>
              <p>
                We’re always looking for the next big project to take the world
                stage. Energi Impact has a fund speciﬁcally for supporting
                people who are creating incredible change in the world.
              </p>
              <p>Apply below to see your dreams come to life.</p>
              <div className="mt-auto">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-warning mt-3"
                  onClick={() => handleModal(MODAL_IDEA, true)}
                >
                  APPLY NOW
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg mt-5">
            <div
              className="d-flex flex-column bg-green p-4 text-white text-center h-100"
              data-aos="fade-right"
            >
              <h3 className="mb-3">DONATE</h3>
              <p>
                Although our project is self-funding, our budget can be limited!
                People like you who give unconditionally are the real heroes
                here, and we deeply appreciate your support.{' '}
              </p>
              <p>
                If you’d like to contribute ﬁnancially to our mission, use the
                link below.
              </p>
              <div className="mt-auto">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-warning mt-3"
                  onClick={() => handleModal(MODAL_DONATE, true)}
                >
                  DONATE NOW
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg mt-5">
            <div
              className="d-flex flex-column bg-green p-4 text-white text-center h-100"
              data-aos="fade-left"
            >
              <h3 className="mb-3">SUGGESTIONS</h3>
              <p>
                We’re constantly striving to improve the impact we have on the
                world, and are constantly hungry for new ideas.{' '}
              </p>
              <p>
                If you can see something that we could be doing better, let us
                know here!
              </p>
              <div className="mt-auto">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-warning mt-3"
                  onClick={() => handleModal(MODAL_SUGGESTION, true)}
                >
                  SUGGESTIONS
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        showModal={modalIdea}
        handleClose={() => handleModal(MODAL_IDEA, false)}
        title="Submit Your Idea"
        theme="green"
      >
        <p className="small">
          if you have a project/passion that you’d love to share and{' '}
          <strong>feel your wisdom could benefit society</strong>, we love
          hearing ideas on how we can create{' '}
          <strong>educational content</strong> with changemakers worldwide.
        </p>
        <p className="small font-weight-bold">
          Simply fill out the form below and we’ll be in touch:
        </p>
        <form
          action="https://docs.google.com/forms/d/e/1FAIpQLSdkpdaC85EyT5btBn8kfD4q_-H0wFG2HFKU955bKnonI6-Drg/formResponse"
          method="post"
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control border-0"
              placeholder="Name*"
              name="entry.621019832"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="date"
              className="form-control border-0"
              placeholder="Date of birth*"
              name="entry.503819038"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control border-0"
              placeholder="Email address*"
              name="entry.992870517"
              onChange={e => setEmailForm(e.target.value)}
              value={emailForm}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              rows="5"
              className="form-control border-0"
              placeholder="Overview of your project/idea (200 words approx)*"
              name="entry.195812567"
              required
            />
          </div>
          <div className="form-group">
            <p>How can we collaborate?</p>
            <div className="form-check">
              <input
                id="radio-financial-support"
                className="form-check-input my-0"
                type="radio"
                name="entry.1500154410"
                value="Financial support"
                required
              />
              <label
                className="form-check-label"
                htmlFor="radio-financial-support"
              >
                Financial support
              </label>
            </div>
            <div className="form-check mt-3">
              <input
                id="radio-Video-Series"
                className="form-check-input my-0"
                type="radio"
                name="entry.1500154410"
                value="Video Series collaboration"
              />
              <label className="form-check-label" htmlFor="radio-Video-Series">
                Video Series collaboration
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="h6 font-weight-bold">Proposal</div>
            <textarea
              className="form-control mt-2"
              rows="8"
              name="entry.839220601"
              placeholder="Please give a detailed overview of how and why you think Energi Impact aligns with your project, and how we could work together. Please provide any relevant links to your project, suggestions on budget/financial contribution needed, and credibility to support your idea. Also please provide any supporting documents/photos (if applicable) The more information, the better!"
            />
          </div>
          <div className="form-group flex-column flex-sm-row d-flex flex-wrap">
            <p className="my-auto">Upload documents/photos (optional)</p>
            <GoogleLogin
              clientId={process.env.GATSBY_GOOGLE_SIGNIN_CLIENT_ID}
              buttonText="Sign in with Google"
              onSuccess={responseOnSuccessGoogle}
              onFailure={responseOnFailureGoogle}
              cookiePolicy="single_host_origin"
              className={`mt-3 mt-sm-0 ml-0 ml-sm-2${
                isGoogleSignedIn ? ' d-none' : ' visible'
              }`}
            />
            {isGoogleSignedIn && (
              <button
                type="button"
                onClick={popupUploadForm}
                className="btn btn-sm btn-success mt-3 mt-sm-0 ml-0 ml-sm-2"
              >
                click here
              </button>
            )}
          </div>

          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.445891543"
              placeholder="What are they three key benefits your project can bring to the world?"
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.1172601017"
              placeholder="Where in the world are you/your project based?"
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.1907361771"
              placeholder="Social media links (Facebook/IG/Youtube/Twitter)"
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.418386428"
              placeholder="Anything else we should know?"
            />
          </div>
          <div className="d-flex flex-row justify-content-between">
            <button
              type="button"
              className="btn btn-success px-sm-5 mt-3 mr-3"
              onClick={() => handleModal(MODAL_IDEA, false)}
            >
              Close
            </button>
            <button type="submit" className="btn btn-success px-sm-5 mt-3">
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        showModal={modalDonate}
        handleClose={() => handleModal(MODAL_DONATE, false)}
        title="Donate"
        theme="green"
      >
        <p className="small">
          Thank you very much for believing in the mission of Energi Impact -
          any support is greatly appreciated and allows us to continue making
          the world a better place.
        </p>
        <p className="small font-weight-bold">
          Simply fill out the form below and we’ll be in touch:
        </p>
        <form
          action="https://docs.google.com/forms/d/e/1FAIpQLSfXvjej13kT6mOZu3VyQxdTiX4-Tnx8hhKSQwzUpi2pzajPqw/formResponse"
          method="post"
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control border-0"
              placeholder="Name*"
              name="entry.1005391090"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="date"
              className="form-control border-0"
              placeholder="Date of birth*"
              name="entry.1826311316"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control border-0"
              placeholder="Email*"
              name="entry.401349483"
              required
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.2088705757"
              placeholder="Why have you chosen to donate?"
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.1969757383"
              placeholder="What project/cause of ours do you most support and why?"
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              name="entry.1003664885"
              placeholder="Donation amount"
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.650710190"
              placeholder="Credit Card/Paypal/Crypto Donation form"
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.809357875"
              placeholder="Social Links (so we can give you a public thank you!)"
            />
          </div>
          <div className="d-flex flex-row justify-content-between">
            <button
              type="button"
              className="btn btn-success px-sm-5 mt-3 mr-3"
              onClick={() => handleModal(MODAL_DONATE, false)}
            >
              Close
            </button>
            <button type="submit" className="btn btn-success px-sm-5 mt-3">
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        showModal={modalSuggestion}
        handleClose={() => handleModal(MODAL_SUGGESTION, false)}
        title="Suggestions"
        theme="green"
      >
        <p className="small">
          We’re constantly trying to improve and increase our Impact in the
          world, and have the utmost respect for the opinions of our audience
          and followers.
        </p>
        <p className="small font-weight-bold">
          Simply fill out the form below and we’ll be in touch:
        </p>
        <form
          action="https://docs.google.com/forms/d/e/1FAIpQLSfIV_X8fmilg4nh_PER08G71gdYoMBoV7a7banusXz23NxuYA/formResponse"
          method="post"
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control border-0"
              placeholder="Name*"
              name="entry.762758729"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control border-0"
              placeholder="Email*"
              name="entry.929405601"
              required
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.2131575438"
              placeholder="What do you think Energi Impact could do better?"
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.43990767"
              placeholder="What do you think Energi Impact is doing well currently, and should do more of?"
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.845247235"
              placeholder="Are there any people or projects you feel Energi Impact could learn from, or collaborate with?"
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.256194208"
              placeholder="Please provide as much detail as possible"
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="2"
              name="entry.749465609"
              placeholder="Anything else you’d like to add?"
            />
          </div>
          <div className="d-flex flex-row justify-content-between">
            <button
              type="button"
              className="btn btn-success px-sm-5 mt-3 mr-3"
              onClick={() => handleModal(MODAL_SUGGESTION, false)}
            >
              Close
            </button>
            <button type="submit" className="btn btn-success px-sm-5 mt-3">
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <div className="container py-5">
        <p className="h5 text-center">
          Our community means the world to us; thank you for being a part of it.{' '}
        </p>
        <p className="h5 text-center">Let’s change the world, together.</p>
      </div>

      <section className="text-white position-relative text-center">
        <Image
          filename="make-your-impact/educate-yourself.jpg"
          className="img-fluid"
          style={{ minHeight: '500px' }}
        />
        <div className="make-your-impact-box py-3 py-lg-5 h-100 bg-alpha-box">
          <div className="container d-flex flex-column align-items-center h-100">
            <div className="my-auto">
              <div className="display-4 font-weight-bold mb-3">
                EDUCATE YOURSELF
              </div>
              <p className="h5 mb-2">
                Explore our extensive education platform to discover how you can
                <br className="d-none d-md-block" /> transform the world with
                your own two hands
              </p>
              <div className="text-center w-100 mt-4">
                <Link to="/#education-section" className="btn btn-success px-4">
                  EDUCATION
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container text-center py-4">
        <p className="h5 my-4">Get involved in our upcoming projects!</p>
        <button
          type="button"
          className="btn btn-sm btn-success text-uppercase"
          onClick={() => handleModal(MODAL_CALENDAR, true)}
        >
          PROJECT CALENDAR
        </button>
      </div>
      <Calendar
        showModal={modalCalendar}
        handleClose={() => handleModal(MODAL_CALENDAR, false)}
        title="Project Calendar"
      />
    </Layout>
  )
}

export default MakeYourImpactPage
